import { useMemo, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { GoogleMap, useLoadScript, Rectangle, OverlayView, Marker } from '@react-google-maps/api';
import Slider from '@mui/material/Slider';
import { setRef } from "@mui/material";


const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
})

function millimetersToPixels(millimeters, latitude, zoom) {
    var meters = millimeters * 0.001;
    const earthCircumference = 40075016.686; // Earth's circumference in meters at the equator
    const metersPerPixel = earthCircumference * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom + 8);
    return meters / metersPerPixel;
}

function calculateDegree(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const angleRad = Math.atan2(y, x);
    let angleDeg = angleRad * (180 / Math.PI);

    if (angleDeg < 0) {
        angleDeg += 360; // Ensure positive degrees (0 to 360)
    }

    return angleDeg;
}

function calculateDestinationPoint(lat, lng, distanceInMeters, bearing) {
    const earthRadius = 6371000; // Earth's radius in meters

    // Convert distance to radians
    const angularDistance = distanceInMeters / earthRadius;

    // Convert angles to radians
    const lat1 = (lat * Math.PI) / 180;
    const lng1 = (lng * Math.PI) / 180;
    const bearingRad = (bearing * Math.PI) / 180;

    const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(angularDistance) +
        Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearingRad)
    );

    const lng2 =
        lng1 +
        Math.atan2(
            Math.sin(bearingRad) * Math.sin(angularDistance) * Math.cos(lat1),
            Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2)
        );

    // Convert back to degrees
    const newLat = (lat2 * 180) / Math.PI;
    const newLng = (lng2 * 180) / Math.PI;

    return { lat: newLat, lng: newLng };
}

function RoofPlannerV3(props) {
    let params = useParams();

    const mapRef = useRef(null);
    const [addPanel, setAddPanel] = useState(false);
    const [addPanelPosition, setAddPanelPosition] = useState({});
    const [drawing, setDrawing] = useState(false);
    const [editDrawing, setEditDrawing] = useState(false);
    const [rotateing, setRotateing] = useState(false);
    const [editPanelGroupIndex, setEditPanelGroupIndex] = useState('');
    const [moving, setMoving] = useState(false);

    const [refresh, setRefresh] = useState(0);
    const [width, setWidth] = useState(998); //mm
    const [height, setHeight] = useState(1690); //mm
    //const [widthMetersToLatitude, setWidthMetersToLatitude] = useState(((width * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    //const [heightMetersToLatitude, setHeightMetersToLatitude] = useState(((height * 0.001) / 2) / 111000); // 1 degree of latitude ≈ 111,000 meters
    const [defaultAngle, setDefaultAngle] = useState(0); // Portrait, Landscape
    const [defaultOrientation, setDefaultOrientation] = useState('Portrait'); // Portrait, Landscape

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBuEcSXUNCT3f9WrObXFVEKr7DDJu64MpU',
        libraries: ["places"]
    });

    const [panelGroups, setPanelGroups] = useState([]);
    const [mapZoom, setMapZoom] = useState(21);
    const [mapCenter, setMapCenter] = useState({ lat: 48.21112738607507, lng: 11.553359538156402 });
    const [position, setPosition] = useState({ lat: 48.21112738607507, lng: 11.553359538156402 });

    const addPanels = () => {
        setDrawing(true);
        setAddPanel(true);
        setEditDrawing(false);
        setEditPanelGroupIndex('');
        setMoving(false);
        setRotateing(false);
        setRefresh(refresh + 1)
    }

    const orientation = (orientation) => {

        if (editPanelGroupIndex === '') {
            setDefaultOrientation(orientation);
        } else {
            var currentpanelGroups = panelGroups;
            currentpanelGroups[editPanelGroupIndex].orientation = orientation;
            setPanelGroups(currentpanelGroups);
        }

        setRefresh(refresh + 1)
    }

    function handleLoad(map) {
        mapRef.current = map;
    }

    function handleCenterChanged() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setPosition(newPos);
        console.log('handleCenterChanged')
    }

    function handleZoomChanged() {
        setMapZoom(this.getZoom());
    }

    const handleMapClick = (event) => {
        console.log('handleMapClick');

        if (drawing && moving === false && rotateing === false) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON

            var currentpanelGroups = panelGroups;
            currentpanelGroups.push({
                position: latLng,
                angle: defaultAngle,
                panel: { row: 1, column: 1 },
                activePanel: [{ x: 0, y: 0 }],
                orientation: defaultOrientation,
                width: width,
                height: height,
            });

            setEditPanelGroupIndex(currentpanelGroups.length - 1);
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1)

        } else if (editDrawing) {

            //setEditPanelGroupIndex(editPanelGroupIndex);
        } else {
            setEditPanelGroupIndex('');
        }
        setEditDrawing(false)
        setDrawing(false);
        setMoving(false);
        setRotateing(false);
        setAddPanel(false);
        setRefresh(refresh + 1)
    };


    const handleMouseMove = (event) => {
        console.log('handleMouseMove')

        if (addPanel && drawing) {
            const latLng = event.latLng && event.latLng.toJSON();
            setAddPanelPosition(latLng);
            setRefresh(refresh + 1);
        } else if (moving && drawing) {
            const latLng = event.latLng && event.latLng.toJSON(); // Get the clicked LatLng as JSON
            var currentpanelGroups = panelGroups;
            currentpanelGroups[editPanelGroupIndex].position = latLng
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
        } else if (rotateing && drawing) {
            var currentpanelGroups = panelGroups;
            const latLng = event.latLng && event.latLng.toJSON(); // Get the current mouse point LatLng as JSON

            const angle = calculateDegree(
                ((currentpanelGroups[editPanelGroupIndex].position.lat) * Math.PI) / 180,
                ((currentpanelGroups[editPanelGroupIndex].position.lng) * Math.PI) / 180,
                (latLng.lat * Math.PI) / 180,
                (latLng.lng * Math.PI) / 180
            );

            currentpanelGroups[editPanelGroupIndex].angle = angle;
            setPanelGroups(currentpanelGroups);
            setRefresh(refresh + 1);
        } else {
            //console.log('handleMouseMove');
            //console.log('moving='+moving);
            // console.log('drawing='+drawing);
        }
    };



    const onClickPanel = (currentPanel, panelGroupKey) => {

        var update = panelGroups;
        var activePanel = update[panelGroupKey].activePanel;

        let foundPosition = -1;
        for (let i = 0; i < activePanel.length; i++) {
            if (activePanel[i].x === currentPanel.x && activePanel[i].y === currentPanel.y) {
                foundPosition = activePanel[i];
                break;
            }
        }

        if (foundPosition !== -1) {
            activePanel.splice(activePanel.indexOf(foundPosition), 1);
        } else {
            activePanel.push(currentPanel);
        }

        update[panelGroupKey].activePanel = activePanel;
        setEditDrawing(true)
        setPanelGroups(update);
        setRefresh(refresh + 1);

        return;
    }

    const activePanelSearch = (currentPanel, panelGroupKey) => {
        var update = panelGroups;
        var activePanel = update[panelGroupKey].activePanel;

        let foundPosition = -1;
        for (let i = 0; i < activePanel.length; i++) {
            if (activePanel[i].x === currentPanel.x && activePanel[i].y === currentPanel.y) {
                foundPosition = i;
                break;
            }
        }

        return foundPosition;
    }

    const memoizedMap = useMemo(() => {
        return (
            <GoogleMap
                id='project-location'
                mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '7px', position: 'relative' }}
                mapContainerClassName={'googleMapContainer'}
                zoom={mapZoom}
                center={mapCenter}

                options={{
                    disableDefaultUI: true,
                    zoomControl: (drawing ? false : true),
                    draggable: (drawing ? false : true),
                    mapTypeId: 'satellite',
                    tilt: 0, // Set tilt to 0 for a top-down view
                    heading: 0, // Set heading to 0 for a top-down view
                }}
                onLoad={handleLoad}
                onClick={handleMapClick}
                onMouseMove={handleMouseMove}
                onTouchMove={(event) => {
                    console.log('onTouchMove')
                    //handleMouseMove(event);
                }}
                //onMouseUp={handleMouseUp}
                onCenterChanged={handleCenterChanged}
                onZoomChanged={handleZoomChanged}
                onMouse

            >
                {panelGroups && panelGroups.map((panelGroup, panelGroupKey) => (
                    <OverlayView
                        key={Math.random()}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={panelGroup?.position}
                        getPixelPositionOffset={getPixelPositionOffset}
                    >
                        <div
                            className="solarPanel"
                            style={{
                                cursor: 'cursor',
                            }}
                        >
                            <div
                                style={{
                                    width: 'max-content',
                                    height: 'max-content',
                                    //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transformOrigin: 'center center',
                                    transition: 'transform 1s ease-in-out',
                                }}
                            >
                                <table
                                    className="solarPanelModule"
                                    style={{
                                        borderCollapse: 'separate',
                                        //borderCollapse: 'collapse', // Merge cell borders
                                        borderSpacing: (panelGroup?.orientation == 'Portrait' ? '0px 1px' : '1px 0px'),
                                    }}
                                >
                                    {[...Array(panelGroup.panel.row)].map((r, x) =>
                                        <tr style={{
                                            //marginBottom: `${(millimetersToPixels(80, position?.lat, mapZoom) - 1)}px`, // Adjust this value to set the space between rows
                                            // display: 'block', // Ensures each row behaves like a block element
                                        }}>
                                            {[...Array(panelGroup.panel.column)].map((c, y) =>
                                                <>
                                                    <td
                                                        style={{
                                                            width: `${(millimetersToPixels((panelGroup?.orientation == 'Portrait' ? panelGroup?.width : panelGroup?.height), position?.lat, mapZoom) - 1)}px`,
                                                            height: `${(millimetersToPixels((panelGroup?.orientation == 'Portrait' ? panelGroup?.height : panelGroup?.width), position?.lat, mapZoom)) - 1}px`,
                                                            border: (editPanelGroupIndex === panelGroupKey ? '3px solid #CFEA00' : (activePanelSearch({ x: x, y: y }, panelGroupKey) !== -1 ? '1px solid #8d8c97' : 'none')),
                                                            //padding: (panelGroup?.orientation == 'Portrait' ? '2px 0px 0px 0px' : '0px 0px 0px 2px'),
                                                            //borderSpacing: '10px', // Remove spacing
                                                            //paddingBottom: '1px',
                                                        }}
                                                        onClick={(e) => {
                                                            var currentPanel = { x: x, y: y };
                                                            if (editPanelGroupIndex === panelGroupKey) {
                                                                onClickPanel(currentPanel, panelGroupKey);
                                                            } else {
                                                                setEditPanelGroupIndex(panelGroupKey);
                                                                setEditDrawing(true)
                                                                setRefresh(refresh + 1)
                                                            }
                                                        }}
                                                        className={activePanelSearch({ x: x, y: y }, panelGroupKey) !== -1 ? 'active' : ''}
                                                    >
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    )}
                                </table >
                            </div >
                        </div>
                    </OverlayView>
                ))}


                {addPanel && addPanelPosition?.lat && addPanelPosition?.lng ? (
                    <OverlayView
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={addPanelPosition}
                        getPixelPositionOffset={getPixelPositionOffset}
                    >
                        <div
                            className="solarPanel"
                            style={{
                                cursor: 'copy',
                            }}
                        >
                            <div
                                style={{
                                    width: 'max-content',
                                    height: 'max-content',
                                    //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                    transform: `translate(-50%, -50%) rotate(${(defaultAngle)}deg)`,
                                    transformOrigin: 'center center',
                                    transition: 'transform 1s ease-in-out',
                                }}
                            >
                                <table className="solarPanelModule" >
                                    {[...Array(1)].map((r, x) =>
                                        <tr>
                                            {[...Array(1)].map((c, y) =>
                                                <>
                                                    <td
                                                        style={{
                                                            width: `${(millimetersToPixels((defaultOrientation == 'Portrait' ? width : height), position?.lat, mapZoom) - 1)}px`,
                                                            height: `${(millimetersToPixels((defaultOrientation == 'Portrait' ? height : width), position?.lat, mapZoom)) - 1}px`,
                                                            border: '3px solid #CFEA00',
                                                        }}
                                                        className={'active'}
                                                    ></td>
                                                </>
                                            )}
                                        </tr>
                                    )}
                                </table >
                            </div >
                        </div>
                    </OverlayView>
                ) : ''}

                {panelGroups && panelGroups.map((panelGroup, panelGroupKey) => (
                    <>
                        {editPanelGroupIndex === panelGroupKey ? (
                            <>
                                <OverlayView
                                    key={Math.random() + '_buttons'}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={panelGroup?.position}
                                    getPixelPositionOffset={getPixelPositionOffset}
                                >
                                    <div
                                        className="solarPanel"
                                        style={{
                                            //transform: `rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                            //transition: 'transform 1s ease-in-out',
                                            // cursor: 'move',
                                            //transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                        }}
                                    >
                                        <div
                                            className="buttons text-center d-flex"
                                            style={{
                                                width: '19px',
                                                marginTop: '0px',
                                            }}
                                        >
                                            <i
                                                style={{
                                                    cursor: 'move',
                                                    transform: `translate(-50%, -50%) rotate(${(panelGroup?.angle ? panelGroup?.angle : 0)}deg)`,
                                                }}
                                                className={(moving ? 'bg-primary' : 'bg-light') + " mx-1 p-1 rounded-circle fa fa-up-down-left-right"}
                                                onMouseDown={(event) => {
                                                    console.log('move button onMouseDown start dragging')
                                                    setEditPanelGroupIndex(panelGroupKey);
                                                    setDrawing(true);
                                                    setMoving(true);
                                                }}

                                                onMouseUp={() => {
                                                    console.log('onMouseUp stop draging')
                                                    setDrawing(false);
                                                    setMoving(false);
                                                }}

                                                onTouchStart={() => {
                                                    console.log('onTouchStart start dragging')
                                                    setEditPanelGroupIndex(panelGroupKey);

                                                    if (moving && drawing) {
                                                        setDrawing(false);
                                                        setMoving(false);
                                                    } else {
                                                        setDrawing(true);
                                                        setMoving(true);
                                                    }
                                                }}
                                            ></i>

                                            {/*
                                            <i
                                                className="bg-light mx-1 p-1 rounded-circle fa fa-rotate"
                                                onMouseDown={(event) => {
                                                    //console.log('move button onMouseDown')
                                                    setEditPanelGroupIndex(panelGroupKey);
                                                    setDrawing(true);
                                                    setRotateing(true);
                                                }}

                                                onClick={() => {
                                                    //console.log('move button onClick')
                                                    setDrawing(false);
                                                    setRotateing(false);
                                                }}
                                            ></i>
                                            */}
                                        </div>
                                    </div>
                                </OverlayView>
                            </>
                        ) : ''}
                    </>
                ))}
            </GoogleMap >
        );
    }, [refresh, mapZoom, width, height, drawing]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 46 || event.key === 'Delete') {
                if (editPanelGroupIndex === '') return;
                const shouldDelete = window.confirm('Are you sure you want to delete?');
                if (shouldDelete) {
                    var update = panelGroups;
                    update.splice(editPanelGroupIndex, 1);
                    setEditPanelGroupIndex('');
                    setPanelGroups(update);
                    setRefresh(refresh + 1);
                }
            }
        };

        if (isLoaded) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (isLoaded) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [isLoaded, editPanelGroupIndex, refresh]);

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return (
        <>
            <div
                className='row'
            >
                <div className='col-md-12'>
                    <div className="card">
                        <div
                            className="card-body p-0"
                            style={{
                                height: 'calc(100vh - 20px)',
                            }}
                        >
                            {memoizedMap}

                            <div style={{
                                position: 'absolute',
                                top: '25px',
                                left: '25px',
                                zIndex: 1000, // Ensure it's above the map
                                maxWidth: '420px'
                            }}>
                                <div
                                    className='row'
                                    style={{ height: '0px' }}>
                                    <div className='col-9'>
                                        <div className='card'>
                                            <div className='card-header p-2 border'>
                                                <h4 className="m-0">Modul Positionierung</h4>
                                            </div>
                                            <div className='card-body p-2'>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">{'Modulausrichtung'}</label>
                                                            <select
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    orientation(e.target.value);
                                                                }}
                                                                value={(editPanelGroupIndex === '' ? defaultOrientation : panelGroups[editPanelGroupIndex]?.orientation)}
                                                            >
                                                                <option value={"Portrait"}>Horizontal</option>
                                                                <option value={"Landscape"}>Vertikal</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group px-1">
                                                            <label className="form-control-label">{'Module Rotieren'}</label>
                                                            <Slider
                                                                size="small"
                                                                value={panelGroups[editPanelGroupIndex]?.angle ? panelGroups[editPanelGroupIndex]?.angle : 0}
                                                                aria-label="Small"
                                                                max={90}
                                                                min={-90}
                                                                step={1}
                                                                valueLabelDisplay="on"
                                                                marks={[
                                                                    {
                                                                        value: 0,
                                                                        //label: '0',
                                                                    }
                                                                ]}
                                                                onChange={(e) => {
                                                                    if (editPanelGroupIndex === '') return;

                                                                    if (typeof e.target.value === 'number') {
                                                                        var update = panelGroups;
                                                                        update[editPanelGroupIndex].angle = e.target.value;

                                                                        setPanelGroups(update);
                                                                        setRefresh(refresh + 1);
                                                                    }

                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group px-1">
                                                            <label className="form-control-label">{'Modul Horizontal'}</label> <br />
                                                            <i
                                                                className="bg-light mx-1 p-1 rounded-circle fa fa-plus mb-1"
                                                                onClick={() => {
                                                                    if (editPanelGroupIndex === '') return;

                                                                    var update = panelGroups;
                                                                    var updateRow = update[editPanelGroupIndex].panel.row + 1;
                                                                    var columns = update[editPanelGroupIndex].panel.column;
                                                                    var activePanel = update[editPanelGroupIndex].activePanel;

                                                                    for (var j = 0; j < columns; j++) {
                                                                        var currentPanel = { x: (updateRow - 1), y: j };
                                                                        activePanel.push(currentPanel);
                                                                    }

                                                                    update[editPanelGroupIndex].activePanel = activePanel;
                                                                    update[editPanelGroupIndex].panel.row = updateRow;
                                                                    setPanelGroups(update);
                                                                    setRefresh(refresh + 1);
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="bg-light mx-1 p-1 rounded-circle fa fa-minus"
                                                                onClick={() => {
                                                                    if (editPanelGroupIndex === '') return;

                                                                    var update = panelGroups;
                                                                    var updateRow = update[editPanelGroupIndex].panel.row - 1;
                                                                    var columns = update[editPanelGroupIndex].panel.column;
                                                                    var activePanel = update[editPanelGroupIndex].activePanel;

                                                                    for (var j = 0; j < columns; j++) {
                                                                        //console.log('j='+j);
                                                                        var currentPanel = { x: (updateRow), y: j };
                                                                        let searchIndex = activePanelSearch(currentPanel, editPanelGroupIndex)
                                                                        if (searchIndex !== -1) {
                                                                            activePanel.splice(activePanel.indexOf(searchIndex), 1);
                                                                        }
                                                                    }

                                                                    if (updateRow === 0) {
                                                                        update.splice(editPanelGroupIndex, 1);
                                                                        setEditPanelGroupIndex('');
                                                                    } else {
                                                                        update[editPanelGroupIndex].activePanel = activePanel;
                                                                        update[editPanelGroupIndex].panel.row = updateRow;
                                                                    }

                                                                    setPanelGroups(update);
                                                                    setRefresh(refresh + 1);
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group px-1">
                                                            <label className="form-control-label">{'Modul Vertikal'}</label> <br />
                                                            <i
                                                                className="bg-light mx-1 p-1 rounded-circle fa fa-plus mb-1"
                                                                onClick={() => {
                                                                    if (editPanelGroupIndex === '') return;

                                                                    var update = panelGroups;
                                                                    var updateColumn = update[editPanelGroupIndex].panel.column + 1;
                                                                    var rows = update[editPanelGroupIndex].panel.row;

                                                                    var activePanel = update[editPanelGroupIndex].activePanel;
                                                                    for (var j = 0; j < rows; j++) {
                                                                        var currentPanel = { x: j, y: (updateColumn - 1) };
                                                                        activePanel.push(currentPanel);
                                                                    }

                                                                    update[editPanelGroupIndex].activePanel = activePanel;
                                                                    update[editPanelGroupIndex].panel.column = updateColumn;
                                                                    setPanelGroups(update);
                                                                    setRefresh(refresh + 1);
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="bg-light mx-1 p-1 rounded-circle fa fa-minus"
                                                                onClick={() => {
                                                                    if (editPanelGroupIndex === '') return;

                                                                    var update = panelGroups;
                                                                    var updateColumn = update[editPanelGroupIndex].panel.column - 1;
                                                                    var rows = update[editPanelGroupIndex].panel.row;

                                                                    var activePanel = update[editPanelGroupIndex].activePanel;
                                                                    for (var j = 0; j < rows; j++) {
                                                                        var currentPanel = { x: j, y: (updateColumn) };
                                                                        let searchIndex = activePanelSearch(currentPanel, editPanelGroupIndex)
                                                                        if (searchIndex !== -1) {
                                                                            activePanel.splice(activePanel.indexOf(searchIndex), 1);
                                                                        }
                                                                    }

                                                                    if (updateColumn === 0) {
                                                                        update.splice(editPanelGroupIndex, 1);
                                                                        setEditPanelGroupIndex('');
                                                                    } else {
                                                                        update[editPanelGroupIndex].activePanel = activePanel;
                                                                        update[editPanelGroupIndex].panel.column = updateColumn;
                                                                    }

                                                                    setPanelGroups(update);
                                                                    setRefresh(refresh + 1);
                                                                }}

                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='col-3'
                                        style={{ height: 'fit-content', width: 'auto' }}
                                    >
                                        <button
                                            type='button'
                                            className='btn btn-primary btn-sm m-0 px-2'
                                            onClick={() => { addPanels() }}
                                            style={{
                                                //backgroundColor: '#CFEA00',
                                            }}
                                        >
                                            <i className='fa fa-plus'></i> <br />
                                            Modul
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default RoofPlannerV3